import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/srv/app/packages/web-ui/src/components/avatar/AvatarSelf.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuspenseOrAuthBoundary"] */ "/srv/app/packages/web-ui/src/components/SuspenseOrAuthBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BreadcrumbsItem","Breadcrumbs"] */ "/srv/app/packages/web-ui/src/lib/primer/Breadcrumbs.ts");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/lib/primer/index.ts");
